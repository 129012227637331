import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, ReplaySubject, lastValueFrom } from 'rxjs';
import {
  Address,
  MailDataObj,
  ThemeData,
  TownshipInfo,
  TownshipPublicSettings,
  VoucherGroup,
} from '../interfaces';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
  ValidatorFn,
  AbstractControl,
} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VerifyPhoneComponent } from './dialogs/verify-phone/verify-phone.component';
import * as moment from 'moment';
import { query, where, collection, getDocs } from 'firebase/firestore';
import { SucceedActivationComponent } from './dialogs/succeed-activation/succeed-activation.component';
import {
  fixTermsUrl,
  getCurrencyString,
  getEnvName,
  getHeaderImageUrl,
  getTownshipInfo,
  getTownshipColors,
} from '../globals';
import { CustomValidators } from '../validators/custom-validators';
// import { decodeRouteParam } from '../globals';
export interface ActivateVoucherComponentData {
  townshipId: string;
  voucherGroupId: string;
  userData: PreFilledUserData;
  width?: string;
  termsAccepted?: boolean;
  colorData?: ThemeData;
  ambitionLevel?: string;
  measures?: string[];
  noStatistics?: boolean;
  activateType?: string;
  preparingRequest?: boolean;
}
export interface PreFilledUserData {
  postal?: string;
  houseNumber?: number;
  houseNumberAddition?: string;
  email?: string;
  phone?: string;
  name?: string;
}
@Component({
  selector: 'app-activate-voucher',
  templateUrl: './activate-voucher.component.html',
  styleUrls: ['./activate-voucher.component.scss'],
})
export class ActivateVoucherComponent implements OnInit {
  loaded: boolean = false;
  env = environment;
  data: ActivateVoucherComponentData;
  preFilledData: PreFilledUserData = {};
  @Input() inputData: ActivateVoucherComponentData;
  noStatistics = false;
  iframeError = true;
  awaitingResponse: boolean;
  townshipId: string;
  townshipName: string;
  uniqueTownshipName?: string;
  voucherGroupId: string;
  townshipUrlFixed: string;
  townshipInfo: TownshipInfo;
  township: TownshipPublicSettings;
  voucherGroup: Observable<VoucherGroup>;
  voucherGroupData: VoucherGroup;
  voucherGroupDoc: AngularFirestoreDocument<VoucherGroup>;
  activateVoucherForm: UntypedFormGroup;
  availableVouchers: number;
  termsRows: number;
  loadingAddressSuffixes: boolean;
  lastFormValue: any;
  isDialog: boolean;
  isEmbedded: boolean;
  isPreperationForm = false;
  noGroupProvided = false;
  firstTime = true;
  pageWidth = '460px';
  activateType = null;

  environment: string;
  error: string;

  headerImg: string;
  showHeaderImg: boolean;
  recaptcha: boolean;

  acitvateDateNotPassed: boolean;
  usableFromDate: any;
  prefilledFormSent: boolean;

  envName: any = {};
  public addresses: ReplaySubject<Address[]> = new ReplaySubject<Address[]>(1);
  validDateToRequest: boolean = true;

  theme: any;

  getCurrencyString = getCurrencyString;

  highContrast: boolean = false;

  constructor(
    private route: ActivatedRoute,
    public db: AngularFirestore,
    private fb: UntypedFormBuilder,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dialogData: ActivateVoucherComponentData
  ) {}

  async ngOnInit() {
    if (
      localStorage.getItem('highContrast') &&
      localStorage.getItem('highContrast') === '1'
    ) {
      this.highContrast = true;
    }

    this.activateVoucherForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      emailConfirm: ['', [Validators.required, Validators.email]],
      terms: [false, Validators.requiredTrue],
    });

    this.activateVoucherForm.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe(async (form) => {
        if (form.houseNumber) {
          form.houseNumber = form.houseNumber.toString();
        } else {
          form.houseNumber = '';
        }
        const lastFormValue = { ...this.lastFormValue };
        this.lastFormValue = form;
        // If postal and housenumber are set get possible suffixes
        if (
          form.postal &&
          form.houseNumber &&
          form.postal.length > 0 &&
          form.houseNumber.length > 0 &&
          (lastFormValue.postal !== form.postal ||
            lastFormValue.houseNumber !== form.houseNumber)
        ) {
          this.loadingAddressSuffixes = true;
          const q = query(
            collection(
              this.db.firestore,
              `/township/${this.townshipId}/addresses/`
            ),
            where('postal', '==', form.postal.toUpperCase()),
            where('houseNumber', '==', form.houseNumber)
          );

          const querySnapshot = (await getDocs(q)).docs;
          const addresses = [];
          querySnapshot.forEach((addressDoc) => {
            const address: any = addressDoc.data();
            if (address.houseNumberAddition) {
              addresses.push(address);
            } else if (querySnapshot.length > 1) {
              address.houseNumberAddition = '';
              addresses.unshift(address);
            }
          });
          if (addresses.length > 0) {
            this.addresses.next(addresses);
          } else {
            this.addresses.next(null);
          }
        }
        // If postal or housenumber we're set, delete suffixes to let people enter their own suffixes again
        if (
          (!form.postal || !form.houseNumber) &&
          (lastFormValue.postal || lastFormValue.houseNumber)
        ) {
          this.loadingAddressSuffixes = false;
          this.addresses.next(null);
        }
      });

    if (
      this.inputData &&
      this.inputData.townshipId &&
      this.inputData.voucherGroupId
    ) {
      console.log('this.inputData', this.inputData);
      this.data = this.inputData;
      this.isEmbedded = true;
      if (this.inputData.preparingRequest) {
        this.isPreperationForm = true;
      }
    }

    if (
      this.dialogData &&
      this.dialogData.townshipId &&
      this.dialogData.voucherGroupId
    ) {
      this.data = this.dialogData;
      this.isDialog = true;
    }

    console.log('this.data', this.data);
    if (this.data && this.data.townshipId && this.data.voucherGroupId) {
      this.preFilledData = this.data.userData;
      this.townshipId = this.data.townshipId;
      this.voucherGroupId = this.data.voucherGroupId;
      this.noStatistics = this.data.noStatistics ?? false;
      this.activateType = this.data.activateType
        ? this.data.activateType
        : null;
      this.theme = this.data.colorData ? this.data.colorData : null;
      if (this.data.width) this.pageWidth = this.data.width;
      if (this.data.termsAccepted) {
        this.activateVoucherForm.controls.terms.setValue(true);
      }
      await this.getInfo();
    }

    this.route.params.subscribe(async (params) => {
      console.log('params', params);
      if (params.townshipName) {
        this.townshipName = params.townshipName;
        this.uniqueTownshipName = params.townshipName;
      }
      if (params.townshipId) {
        this.townshipId = params.townshipId;
      }
      if (params.voucherGroupId) {
        this.voucherGroupId = params.voucherGroupId;
      } else {
        this.noGroupProvided = true;
      }
      if (params.address) {
        const addressSplit = params.address.split('*');
        if (addressSplit.length < 2) {
          return;
        }
        this.preFilledData.postal = addressSplit[0];
        this.preFilledData.houseNumber = addressSplit[1];
        this.preFilledData.houseNumberAddition = addressSplit[2];
      }
      if (params.customParams) {
        const customParamsSplit = params.customParams.split('&');
        customParamsSplit.forEach((param) => {
          const paramSplit = param.split('=');
          const key = paramSplit[0];
          const value = paramSplit[1];
          // console.log('key', key);
          // console.log('value', value);
          this.preFilledData[key] = value;
        });
      }
      if (params.email) {
        this.preFilledData.email = params.email;
      }
      if (params.phone) {
        this.preFilledData.phone = params.phone;
      }
      if (params.name) {
        this.preFilledData.name = params.name;
      }
      console.log('this.preFilledData', this.preFilledData);
      await this.getInfo();
    });

    this.translate.setDefaultLang(environment.language);
    this.translate.use(environment.language);
  }

  verifyPhone(phone: string) {
    const dialogRef = this.dialog.open(VerifyPhoneComponent, {
      width: '350px',
      data: { townshipId: this.townshipId, phone },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(async (code) => {
      if (code) {
        this.submit(code !== 'skip' ? code : null);
      }
    });
  }
  getError(name) {
    const field = this.activateVoucherForm.get(name);
    if (field.touched || !field.pristine) {
      let error;
      if (field.hasError('required')) {
        error = 'required';
      }
      if (field.hasError('startsWith')) {
        error = 'muststartwith06';
      }
      if (field.hasError('number')) {
        error = 'number';
      }
      if (field.hasError('email')) {
        error = 'email';
      }
      if (field.hasError('pattern')) {
        error = 'pattern';
      }
      if (field.hasError('minlength')) {
        error = 'phone';
      }
      if (field.hasError('ibanValidator')) {
        error = 'invalid';
      }
      const res = this.translate.instant(`errors.${error}`);
      return res as string;
    }
    return '';
  }
  async getInfo() {
    this.townshipInfo = await getTownshipInfo({
      townshipName: this.townshipName,
      townshipId: this.townshipId,
    });
    this.townshipId = this.townshipInfo.townshipId;
    this.townshipName = this.townshipInfo.townshipName;
    this.township = this.townshipInfo.township;

    if (this.township.logoImageUrl) {
      this.headerImg = this.township.logoImageUrl;
    } else {
      this.headerImg = getHeaderImageUrl(this.township.voucherPrefix);
    }
    this.townshipUrlFixed = fixTermsUrl(this.township.termsUrl);
    this.envName = getEnvName(this.township.voucherPrefix);
    this.theme = await getTownshipColors(this.township);

    if (this.voucherGroupId) {
      console.log('this.voucherGroupId', this.voucherGroupId);
      this.voucherGroupDoc = this.db.doc<VoucherGroup>(
        'township/' + this.townshipId + '/voucherGroups/' + this.voucherGroupId
      );
      this.voucherGroup = this.voucherGroupDoc.snapshotChanges().pipe(
        map((actions) => {
          const data = actions.payload.data() as VoucherGroup;
          this.termsRows = 5;
          console.log('data', data);
          data.id = actions.payload['id'];

          data.totalVouchers = data.totalVouchers ? data.totalVouchers : 0;
          data.activatedVouchers = data.activatedVouchers
            ? data.activatedVouchers
            : 0;

          if (data.maxVouchers && data.totalVouchers > data.maxVouchers) {
            data.totalVouchers = data.maxVouchers;
          }
          if (!data.claimedVouchers) {
            data.claimedVouchers = 0;
          }
          if (!data.activatedVouchers) {
            data.activatedVouchers = 0;
          }
          if (!data.reservedVouchers) {
            data.reservedVouchers = 0;
          }
          if (!data.toBeActivated) {
            data.toBeActivated = 0;
          }
          const reservedVouchers = data.reservedVouchers + data.toBeActivated;
          this.availableVouchers =
            data.totalVouchers - data.activatedVouchers - reservedVouchers;
          if (data.maxVouchers && data.maxVouchers < data.totalVouchers) {
            this.availableVouchers = data.maxVouchers - data.activatedVouchers;
          }
          if (data.activateFromType === 'activateOnDate') {
            if (data.usableFrom.toDate() > new Date()) {
              this.usableFromDate = data.usableFrom.toDate();
              this.acitvateDateNotPassed = true;
              console.log('activate date not passed');
            }
          }

          console.log('availableVouchers', this.availableVouchers);
          if (this.availableVouchers <= 0 && data.type !== 'requestActivated') {
            this.activateVoucherForm.disable();
          } else if (!this.awaitingResponse) {
            this.activateVoucherForm.enable();
          }

          if (!this.validDateToRequest) {
            this.activateVoucherForm.disable();
          }
          return { ...data };
        })
      );
      this.voucherGroup.subscribe((voucherGroup) => {
        console.log('voucherGroup', voucherGroup);
        this.voucherGroupData = voucherGroup;

        if (voucherGroup.validUntilDate) {
          // Check if you are requesting a voucher within the allowed timeframe
          const dateThatIsValid = new Date(
            voucherGroup.validUntilDate.toDate()
          ).setDate(
            voucherGroup.validUntilDate.toDate().getDate() -
              (voucherGroup.requestCouponDays
                ? voucherGroup.requestCouponDays
                : 0)
          );
          console.log('dateThatIsValid', new Date(dateThatIsValid));
          if (moment(new Date()).isAfter(dateThatIsValid)) {
            this.validDateToRequest = false;
          }
        }

        if (!voucherGroup.hideHeaderImage) {
          this.showHeaderImg = true;
        } else {
          this.showHeaderImg = false;
        }

        if (voucherGroup.requestName && !voucherGroup.requestIdentification) {
          this.activateVoucherForm.addControl(
            'name',
            new UntypedFormControl('', Validators.required)
          );
        }
        if (voucherGroup.requestIdentification) {
          this.activateVoucherForm.addControl(
            'identification',
            new UntypedFormControl('', Validators.required)
          );
        }
        if (voucherGroup.requestPhone) {
          if (voucherGroup.verifyPhone) {
            this.activateVoucherForm.addControl(
              'phone',
              new UntypedFormControl('06', [
                Validators.required,
                Validators.minLength(10),
                Validators.maxLength(10),
                startsWith('06'),
              ])
            );
          } else {
            this.activateVoucherForm.addControl(
              'phone',
              new UntypedFormControl('06', [
                Validators.required,
                Validators.minLength(10),
                Validators.maxLength(10),
              ])
            );
          }
        }
        if (voucherGroup.type === 'activate') {
          this.activateVoucherForm.addControl(
            'number',
            new UntypedFormControl('', Validators.required)
          );
        }

        if (!voucherGroup.dontRequestAddress) {
          this.activateVoucherForm.addControl(
            'postal',
            new UntypedFormControl('', Validators.required)
          );
          this.activateVoucherForm.addControl(
            'houseNumber',
            new UntypedFormControl('', [
              Validators.required,
              number({ min: 1 }),
            ])
          );
          this.activateVoucherForm.addControl(
            'houseNumberAddition',
            new UntypedFormControl('')
          );
          if (this.preFilledData && this.firstTime) {
            this.firstTime = false;
            let addressSet = false;
            if (this.preFilledData.postal) {
              this.activateVoucherForm.controls.postal?.setValue(
                this.preFilledData.postal
              );
              addressSet = true;
            }
            if (this.preFilledData.houseNumber) {
              this.activateVoucherForm.controls.houseNumber?.setValue(
                this.preFilledData.houseNumber
              );
              addressSet = true;
            }
            if (this.preFilledData.houseNumberAddition) {
              this.activateVoucherForm.controls.houseNumberAddition?.setValue(
                this.preFilledData.houseNumberAddition
              );
              addressSet = true;
            }
            console.log('addressSet', addressSet);
            if (addressSet) {
              console.log('disabling address inputs');
              this.activateVoucherForm.controls.postal?.disable();
              this.activateVoucherForm.controls.houseNumber?.disable();
              this.activateVoucherForm.controls.houseNumberAddition?.disable();
            }
            if (this.preFilledData.email) {
              console.log('disabling and setting email inputs');
              this.activateVoucherForm.controls.email?.setValue(
                this.preFilledData.email
              );
              this.activateVoucherForm.controls.emailConfirm?.setValue(
                this.preFilledData.email
              );
              this.activateVoucherForm.controls.email?.disable();
              this.activateVoucherForm.controls.emailConfirm?.disable();
            }
            if (this.preFilledData.phone) {
              this.activateVoucherForm.controls.phone?.setValue(
                this.preFilledData.phone
              );
            }
            if (this.preFilledData.name) {
              this.activateVoucherForm.controls.name?.setValue(
                this.preFilledData.name
              );
              this.activateVoucherForm.controls.identification?.setValue(
                this.preFilledData.name
              );
              this.activateVoucherForm.controls.bankAccountName?.setValue(
                this.preFilledData.name
              );
            }
          }
        }
        if (voucherGroup.cashback) {
          this.activateVoucherForm.addControl(
            'ibanNumber',
            new UntypedFormControl('', [
              Validators.required,
              CustomValidators.ibanValidator,
            ])
          );
          this.activateVoucherForm.addControl(
            'bankAccountName',
            new UntypedFormControl('', [Validators.required])
          );
        }
      });
    } else {
      this.activateVoucherForm.addControl(
        'number',
        new UntypedFormControl('', Validators.required)
      );
      this.activateVoucherForm.addControl('name', new UntypedFormControl(''));
      this.activateVoucherForm.addControl(
        'postal',
        new UntypedFormControl('', Validators.required)
      );
      this.activateVoucherForm.addControl(
        'houseNumber',
        new UntypedFormControl('', [Validators.required, number({ min: 1 })])
      );
      this.activateVoucherForm.addControl(
        'houseNumberAddition',
        new UntypedFormControl('')
      );
      this.activateVoucherForm.addControl(
        'phone',
        new UntypedFormControl('06', [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
          startsWith('06'),
        ])
      );
    }
    this.loaded = true;
  }
  async submit(verificationCode?: string) {
    if (!this.awaitingResponse && this.activateVoucherForm.valid) {
      this.awaitingResponse = true;
      const form = this.activateVoucherForm.getRawValue();
      this.activateVoucherForm.disable();
      const email = form.email as string;
      const emailConfirm = form.emailConfirm as string;
      if (email !== emailConfirm) {
        this.awaitingResponse = undefined;
        this.activateVoucherForm.enable();
        this.snackBar.open(this.getTranslation('email-dont-match'), '', {
          duration: 5000,
        });
        return;
      }
      let postal: string;
      let houseNumber: string;
      let houseNumberAddition: string;
      if (!this.voucherGroupData?.dontRequestAddress) {
        postal = form.postal as string;
        postal = postal.toUpperCase().replace(/\s/g, '');
        houseNumber = form.houseNumber.toString();
        houseNumberAddition = form.houseNumberAddition as string;
      }
      const code = form.number ? form.number : null;
      const name = form.name ? form.name : null;
      const phone = form.phone ? form.phone : null;
      const identification = form.identification ? form.identification : null;
      let res: Observable<any>;

      const requestUrl = `${this.env.functionsUrl}/httpVoucher`;

      const body = {
        townshipId: this.townshipId,
        voucherGroupId: this.voucherGroupId,
        ambitionLevel: this.inputData?.ambitionLevel,
        email,
        postal: postal ? postal : null,
        houseNumber: houseNumber ? houseNumber : null,
        houseNumberAddition:
          houseNumberAddition && houseNumberAddition.length
            ? houseNumberAddition.toLowerCase()
            : null,
        code: code ? code : null,
        name: name ? name : null,
        phone: phone ? phone : null,
        verificationCode: verificationCode ? verificationCode : null,
        identification: identification ? identification : null,
        ibanNumber: form.ibanNumber ? form.ibanNumber.replace(/\s/g, '') : null,
        bankAccountName: form.bankAccountName ?? null,
      };

      if (this.data?.measures) {
        body['measures'] = this.data.measures;
      }
      console.log('body', body);
      res = this.http.post(requestUrl, body);
      res.subscribe(async (result) => {
        this.awaitingResponse = undefined;
        console.log('result', result);
        if (
          result.status === 'verification_code_sent' ||
          result.status === 'verification_code_incorrect'
        ) {
          // Should show verification screen
          if (result.status === 'verification_code_incorrect') {
            this.getTranslation(result.status);
          }
          this.verifyPhone(phone);
        } else if (result.status === 'success') {
          this.activateVoucherForm.reset();
          this.dialog.open(SucceedActivationComponent, {
            width: '350px',
            data: { voucherGroup: result.voucherGroup },
            disableClose: true,
          });
        } else {
          this.getTranslation(result.status);
        }
        this.activateVoucherForm.enable();
      });
    }
  }

  async sendPreFilledForm() {
    if (!this.uniqueTownshipName) {
      return this.snackBar.open(
        'Voorbereiden van formulieren werkt alleen wanneer de unieke naam ingesteld is.',
        '',
        {
          duration: 5000,
        }
      );
    }
    const form = this.activateVoucherForm.getRawValue();
    this.activateVoucherForm.disable();
    if (form.email !== form.emailConfirm) {
      this.awaitingResponse = undefined;
      this.activateVoucherForm.enable();
      return this.snackBar.open(this.getTranslation('email-dont-match'), '', {
        duration: 5000,
      });
    }
    let addressParam = `${form.postal}*${form.houseNumber}`;
    if (form.houseNumberAddition) {
      addressParam = `${addressParam}*${form.houseNumberAddition}`;
    }
    const iframeUrl = `${window.location.protocol}//${window.location.host}`;
    let activateVoucherUrl = `${iframeUrl}/activate/${this.uniqueTownshipName}/${this.voucherGroupId}/${addressParam}/email=${form.email}`;
    if (form.phone) {
      activateVoucherUrl = `${activateVoucherUrl}&phone=${form.phone}`;
    }
    let name = '';
    if (form.name || form.identification || form.bankAccountName) {
      name = form.name ?? form.identification ?? form.bankAccountName;
      activateVoucherUrl = `${activateVoucherUrl}&name=${name}`;
    }
    console.log('activateVoucherUrl', activateVoucherUrl);
    const dataObj: MailDataObj = {
      orgData: {
        townshipId: this.townshipId,
        voucherGroup: this.voucherGroupData,
      },
      mailData: {
        mailType: 'preFilledActivation',
        downloadUrl: activateVoucherUrl,
      },
      userData: { email: [form.email], name: name },
    };
    try {
      this.awaitingResponse = true;
      const sendMailRes = (await lastValueFrom(
        this.http.post(`${this.env.functionsUrl}/httpSendMail`, {
          data: dataObj,
        })
      )) as any;
      console.log('sendMailRes', sendMailRes);
      if (sendMailRes.status != 'success') {
        throw Error(sendMailRes.status);
      }
      this.prefilledFormSent = true;
      this.awaitingResponse = false;
      this.snackBar.open(
        'Het aanvraagformulier is successvol verstuurd.',
        'X',
        {
          duration: 5000,
        }
      );
    } catch (e) {
      this.awaitingResponse = false;
      console.error(e);
      this.snackBar.open('Er is iets misgegaan met verzenden van de bon', 'X', {
        duration: 5000,
      });
    }
  }

  getTranslation(objName: string) {
    let message = this.translate.instant(
      `snackbar-messages.${objName}`
    ) as string;
    console.log('message', message);
    if (message.includes('snackbar-messages.')) {
      // translation not found, get oops error instead
      message = this.translate.instant(
        `snackbar-messages.something-went-wrong`
      ) as string;
    }
    this.snackBar.open(message, 'X', {
      duration: 12000,
    });
    return message;
  }

  setHighContrast() {
    this.highContrast = this.highContrast ? false : true;
    localStorage.setItem('highContrast', this.highContrast ? '1' : '0');
  }
}
export function startsWith(startsWithVal): ValidatorFn {
  console.log('startsWithVal', startsWithVal);

  return (control: AbstractControl): { [key: string]: any } => {
    const value = control.value ? control.value : '';
    const isStarting = 0 === value.indexOf(startsWithVal);
    return isStarting ? null : { startsWith: true };
  };
}
export function number(prms: { min?: number; max?: number } = {}): ValidatorFn {
  return (control: UntypedFormControl): { [key: string]: any } => {
    let val: number = control.value;
    console.log;
    if (!val && val !== 0) {
      return null;
    }
    if (isNaN(val) || /\D/.test(val.toString())) {
      return { number: true };
    } else if (!isNaN(prms.min) && !isNaN(prms.max)) {
      return val < prms.min || val > prms.max ? { number: true } : null;
    } else if (!isNaN(prms.min)) {
      return val < prms.min ? { number: true } : null;
    } else if (!isNaN(prms.max)) {
      return val > prms.max ? { number: true } : null;
    } else {
      return null;
    }
  };
}
