// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: Viga;
  src: url('Viga-Regular.ttf');
}
::ng-deep .mat-mdc-form-field {
  --mat-form-field-container-text-line-height: 26px;
}

::ng-deep .mat-mdc-unelevated-button:not(:disabled),
::ng-deep .mat-mdc-raised-button:not(:disabled) {
  background-color: var(--primary) !important;
  color: var(--primary-contrast) !important;
}

::ng-deep .mat-mdc-unelevated-button:not(:disabled).header-button {
  background-color: white !important;
  color: var(--primary) !important;
}

::ng-deep .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
::ng-deep .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label--float-above,
::ng-deep .mat-mdc-form-field-error {
  color: var(--warn) !important;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,4BAAA;AACF;AAEA;EACE,iDAAA;AAAF;;AAGA;;EAEE,2CAAA;EACA,yCAAA;AAAF;;AAGA;EACE,kCAAA;EACA,gCAAA;AAAF;;AAGA;;;EAWE,6BAAA;AARF","sourcesContent":["@font-face {\n  font-family: Viga;\n  src: url('../assets/fonts/Viga/Viga-Regular.ttf');\n}\n\n::ng-deep .mat-mdc-form-field {\n  --mat-form-field-container-text-line-height: 26px;\n}\n\n::ng-deep .mat-mdc-unelevated-button:not(:disabled),\n::ng-deep .mat-mdc-raised-button:not(:disabled) {\n  background-color: var(--primary) !important;\n  color: var(--primary-contrast) !important;\n}\n\n::ng-deep .mat-mdc-unelevated-button:not(:disabled).header-button {\n  background-color: white !important;\n  color: var(--primary) !important;\n}\n\n::ng-deep\n  .mdc-text-field--outlined.mdc-text-field--invalid:not(\n    .mdc-text-field--disabled\n  )\n  .mdc-floating-label,\n::ng-deep\n  .mdc-text-field--outlined.mdc-text-field--invalid:not(\n    .mdc-text-field--disabled\n  )\n  .mdc-floating-label--float-above,\n::ng-deep .mat-mdc-form-field-error {\n  color: var(--warn) !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
